import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchBeds(ctx, params) {
      return useJwt.getBeds(params).then(response => response);
    },
    fetchAnalytic(ctx, params) {
      return useJwt.getBedAnalytics(params).then(response => response);
    },
    exportBeds(ctx, params) {
      return useJwt.exportBeds(params).then(response => response);
    },
    createBed(ctx, data) {
      return useJwt.createBed(data).then(response => response);
    },
    updateBed(ctx, data) {
      return useJwt.updateBed(data).then(response => response);
    },
    getBedDetail(ctx, id) {
      return useJwt.getBedDetail(id).then(response => response);
    },
    deleteBeds(ctx, data) {
      return useJwt.deleteBeds(data).then(response => response);
    },
  },
};
