import { ref } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { useRouter } from '@core/utils/utils';

export default function useSaleEmptyRoom(props, emit, refFormObserver) {
  const toastification = toast();

  const { route } = useRouter();
  const salePathSlug = route.value.params.slug;
  const salePath = salePathSlug.substring(salePathSlug.lastIndexOf('-') + 1);

  const refForm = ref(refFormObserver);
  const apartments = ref([]);
  const room = ref(null);
  const bed = ref(null);
  const isLoading = ref(false);

  const resetModal = () => {

  };

  const fetchData = () => {
    isLoading.value = true;
    store.dispatch('sale/getSalePathData', salePath)
      .then(response => {
        apartments.value = response.data;
        isLoading.value = false;
      })
      .catch(error => {
        toastification.showToastError(error);
        isLoading.value = false;
      });
  };

  const onSubmit = async () => {

  };
  const viewRoomDetail = val => {
    room.value = val;
  };

  const viewBedDetail = val => {
    bed.value = val;
  };

  return {
    refForm,
    apartments,
    room,
    bed,
    isLoading,
    resetModal,
    onSubmit,
    fetchData,
    viewRoomDetail,
    viewBedDetail,
  };
}
