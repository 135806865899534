<template>
  <div
    v-if="isLoading"
    class="d-flex justify-content-center mb-1"
  >
    <b-spinner

      class="text-center"
      variant="secondary"
    />
  </div>
  <b-card
    v-else
    no-body
  >
    <b-card-header>
      <b-card-title class="text-primary text-center">
        BẢNG HÀNG NGUỒN PHÒNG TRỐNG & SẮP TRỐNG
      </b-card-title>
    </b-card-header>
    <b-card-body>
      <div
        v-if="!apartments || apartments.length === 0"
        class="misc-wrapper"
      >

        <div class="misc-inner p-2 p-sm-3">
          <div class="w-100 text-center">
            <h2 class="mb-1">
              Hiện đang không có phòng nào trống hoặc sắp trống!
            </h2>
            <p class="mb-2">
              Hãy quay lại sau nhé. Thông tin phòng/giường trống sẽ xuất hiện ngay sau khi chúng tôi cập nhật
            </p>

            <b-img
              fluid
              src="@/assets/images/pages/not-authorized-dark.svg"
              alt="Not authorized page"
            />
          </div>
        </div>
      </div>
      <b-list-group
        v-if="apartments && apartments.length > 0"
        class="list-group-shortcut"
      >
        <b-list-group-item
          v-for="(apartment,index) in apartments"
          :key="index"
        >
          <apartment-item
            :apartment="apartment"
            @view-room-detail="viewRoomDetail"
            @view-bed-detail="viewBedDetail"
          />
        </b-list-group-item>
      </b-list-group>
    </b-card-body>
    <room-detail-modal
      :room-id="room ? room.id : 0"
      :is-public="true"
    />
    <bed-detail-modal
      :bed-id="bed ? bed.id : 0"
      :is-public="true"
    />
  </b-card>

</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import {

  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BListGroup,
  BListGroupItem,
  BSpinner,
  BImg,
} from 'bootstrap-vue';

import { required, email, agreement } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
// eslint-disable-next-line import/no-cycle
import useSaleEmptyRoom from './useSaleEmptyRoom';
import RoomDetailModal from '../property/room/detail/RoomDetailModal.vue';
import BedDetailModal from '../property/bed/detail/BedDetailModal.vue';
import ApartmentItem from './ApartmentItem.vue';

export default {
  components: {

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BListGroup,
    BListGroupItem,
    BSpinner,
    BImg,
    RoomDetailModal,
    BedDetailModal,
    ApartmentItem,
  },
  setup(props, { emit }) {
    const { refFormObserver } = formValidation();

    const {
      apartments,
      room,
      bed,
      isLoading,
      resetItemLocal,
      resetModal,
      fetchData,
      onSubmit,
      viewRoomDetail,
      viewBedDetail,
    } = useSaleEmptyRoom(props, emit, refFormObserver);

    return {
      apartments,
      room,
      bed,
      resetItemLocal,
      isLoading,
      resetModal,
      fetchData,
      onSubmit,
      viewRoomDetail,
      viewBedDetail,
    };
  },
  data() {
    return {
      // validator
      required,
      email,
      agreement,

    };
  },
  created() {
    this.fetchData();
  },
};
</script>
