<template>
  <div class="">

    <b-row>
      <b-col cols="12">
        <div class="font-small-4 font-weight-bolder text-uppercase text-primary">
          {{ apartment.name }}
        </div>
      </b-col>
      <b-col cols="12">
        <div class="">
          {{ apartment.fullAddress }}
        </div>
      </b-col>
    </b-row>

    <b-list-group
      class="list-group-shortcut mt-2"
    >
      <b-list-group-item
        v-for="(floor,index) in apartment.layout"
        :key="index"
      >
        <floor-item
          :floor="floor"
          @view-room-detail="viewRoomDetail"
          @view-bed-detail="viewBedDetail"
        />
      </b-list-group-item>
    </b-list-group>

  </div>

</template>

<script>
import {
  BCol, BRow, BListGroup, BListGroupItem,
} from 'bootstrap-vue';
import FloorItem from '../shortcut/components/FloorItem.vue';

export default {
  components: {
    FloorItem,
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
  },
  props: {
    apartment: {
      type: Object,
      default: () => {},
    },
  },
  computed: {

  },
  methods: {
    viewBedDetail(bed) {
      this.$emit('view-bed-detail', bed);
    },
    viewRoomDetail(room) {
      this.$emit('view-room-detail', room);
    },
  },

};
</script>
